<template>
  <div class="lsg-cabinet" v-if="$store.state.me.role !== 'partner' && $store.state.me.role !== 'agent'">
    <div class="modal" v-if="showModal">
      <div class="modal__back" @click="showModal = false"></div>
      <div class="modal__body" style="flex-direction: column">
        <div><h1>Добавление курса</h1></div>
        <input type="text" v-model="newCourseData.title" placeholder="Заголовок" style="margin-bottom: 20px">
        <input type="link" v-model="newCourseData.link" placeholder="Ссылка" style="margin-bottom: 20px">
        <textarea cols="30" v-model="newCourseData.description" rows="10" placeholder="Описание" style="margin-bottom: 20px"/>
        <div class="buttons" style="display: flex; justify-content: space-between; width: 100%">
          <div class="btn" @click="createCourse">Добавить</div>
          <div class="btn btn--gray" @click="showModal = false">Отмена</div>
        </div>
      </div>
    </div>
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <div v-if="$store.state.me.courses && $store.state.me.courses.length > 0" style="display: flex; margin: 0 40px; flex-direction: column; flex-wrap: wrap; justify-content: center; align-items: flex-start">
        <div class="cabinet__list--client">
          <a
            class="cabinet__item--client"
            :class="{ 'course-blocked': item.block }"
            v-for="(item, index) in $store.state.me.courses
              .map((elem, index) => {
                if (index !== 0 && $store.state.me.courses[index - 1].completion_date || elem.completion_date || $store.state.me.courses[index - 1] && $store.state.me.courses[index - 1].viewed) return elem
                else if (index === 0) return elem
                else if (/\/course\//.test(elem.link)) return elem
                else return { ...elem, block: true }
              })"
            :key="index"
            @click="viewCourse(item.link, item.block)"
          >
            <div v-if="item && item.completion_date" class="watermark"><span>Завершён</span></div>
            <div v-else-if="item.deadline && (!item.completion_date/** true */ && !(/\/course\//.test(item.link) && item.viewed)) && new Date().getTime() > new Date(item.deadline).getTime()" class="watermark watermark--overdue"><span>Просрочен</span></div>
            <div v-else-if="item.viewed && !item.block" class="watermark watermark--viewed"><span>Просмотрен</span></div>
            <a
              class="cabinet__item--client__state"
            >
              <svg class="strelka-right-5" viewBox="0 0 154 109">
                <symbol id="strelka" viewBox="-73.9 -55.3 135.9 85.4">
                  <g>
                    <polygon points="-73.9,29.1 -6,-9.2 61.9,30.1 61.9,14.2 -6,-25.1 -73.9,14.2 "></polygon>
                  </g>
                  <g>
                    <polygon points="-73.9,-1 -6,-39.3 61.9,0 61.9,-16 -6,-55.3 -73.9,-16 "></polygon>
                  </g>
                </symbol>
                <use xlink:href="#strelka" width="135.9" height="85.4" id="XMLID_1_" x="-73.9" y="-55.3" transform="matrix(1.007 0 0 -1.007 83.0005 42)" ></use>
              </svg>
            </a>
            <h3 class="cabinet__item--client__name">{{ item.title }}</h3>
            <div class="cabinet__item--client__contact">
              <span>{{item.description}}</span>
              <br>
              <span>Время прохождения: 60 мин</span>
              <span v-if="!/\/course\//.test(item.link)">Дата прохождения: {{ item.completion_date ? new Date(item.completion_date).toLocaleDateString(): 'не пройдено' }}</span>
              <span v-if="item.deadline">{{ `Срок: ${new Date(item.deadline).toLocaleDateString()}` }}</span>
            </div>
          </a>
          <a class="cabinet__item--client cabinet__item--plus" v-if="['admin', 'headmanager', 'callcenter'].includes($store.state.me.role)" @click="() => { showModal = true }">
            +
          </a>
        </div>
      </div>
      <h3 v-if="['admin', 'headmanager', 'callcenter'].includes($store.state.me.role)" style="margin: 35px auto 15px; text-align: center">Отчёт</h3>
      <div v-if="['admin', 'headmanager', 'callcenter'].includes($store.state.me.role)" class="tbl">
        <div class="tbl__header">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <v-select
                appentToBody
                placeholder="Статус"
                :options="statusSelect"
                :reduce="status => status.value"
                v-model="filter.status"
                :value="filter"
                @input="onStatusChange"
              >
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <input type="checkbox" v-model="clientFilter.deadline">
              <span>Сроки</span>
            </label>
            <label class="tbl__filter__item">
              <input type="checkbox" v-model="clientFilter.empty">
              <span style="white-space: nowrap">Без курсов</span>
            </label>
          </div>
        </div>
        <div class="tbl__actions" v-if="selectedRows.length > 0 && (['admin'].includes($store.state.me.role) || ['60b9c160d244fb050376f2c5', '5f0d73200ffedb935485c9c0', '5ed790c05f808c5d3639ae20'].includes($store.state.me._id))">
          <div class="tbl__actions__main">
            <div class="tbl__actions__count">
              <span>Выбрано: {{ selectedRows.length }}</span>
            </div>
            <div class="tbl__actions__items">
              <div class="tbl__actions__item" style="cursor: pointer">
                <a :class="{'active': actionType === 'switchCourseList'}" @click.prevent="actionType = 'switchCourseList'">
                  <i aria-hidden="true">
                    <responsible-icon/>
                  </i>
                  <span>Добавить/Изменить курс</span>
                </a>
                <div class="table-actions__drop" v-if="actionType === 'switchCourseList'">
                  <div class="table-actions__container" v-click-outside="vcoConfig">
                    <v-select appendToBody :options="[...allCourses.map(item => ({ value: item.id, label: item.title })), ...[{ value: 'managers', label: 'Менеджеры' }, { value: 'back', label: 'Бэк' }]]" @input="(e) => { this.selectedCourse = e }" placeholder="Выберите курс">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                    <input type="date" v-model="period" style="margin-top: 10px">
                    <div style="width: 100% !important; margin-top: 10px; display: flex; flex-direction: row; justify-content: space-between">
                      <button type="button" class="btn" @click="onUserCourseChange">Сохранить</button>
                      <button v-if="['back', 'managers'].includes(selectedCourse ? selectedCourse.value : null)" type="button" class="btn" disabled>Удалить</button>
                      <button v-else type="button" class="btn" @click="unpinCourse">Удалить</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tbl__actions__rest">
            <a @click.prevent="switchDeleteModal" class="red bold" href="#">
              <span>Удалить</span>
            </a>
          </div>
        </div>
        <template>
          <ApolloQuery
            :query="require('@/graphql/queries/AllUsersCourses.gql')"
            :variables="{ filter }"
            @result="doneLoadingUserCourses"
          >
            <template slot-scope="{ result: { loading, error } }">
              <template v-if="!loading && !error">
                <vue-good-table
                  :columns="tableCourses"
                  :rows="clientFilter.empty ? rows.filter(elem => elem.courses.length === 0) : rows"
                  :select-options="{
                    enabled: ['admin'].includes($store.state.me.role) || ['60b9c160d244fb050376f2c5', '5f0d73200ffedb935485c9c0', '5ed790c05f808c5d3639ae20'].includes($store.state.me._id),
                    disableSelectInfo: true,
                  }"
                  @on-selected-rows-change="selectedRowsChange"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'name'">{{ props.row.name }}</span>
                    <div
                      v-for="(course, index) in props.row.courses"
                      :key="index"
                    >
                      <template v-if="checkTitle(props.column.label, course.title)">
                        <div class="tag tag--green" v-if="course.completion_date">
                          Завершен
                        </div>
                        <div class="tag" v-else-if="course.viewed">
                          Просмотрен
                        </div>
                        <div class="tag tag--red" v-else-if="new Date(course.deadline).getTime() < new Date().getTime()">
                          Просрочен
                        </div>
                        <div class="tag tag--yellow" v-if="course.deadline">
                          Срок: {{ new Date(course.deadline).toLocaleDateString() }}
                        </div>
                      </template>
                    </div>
                  </template>
                </vue-good-table>
              </template>
            </template>
          </ApolloQuery>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VIEW_COURSE from '@/graphql/mutations/ViewCourse.gql'
import ALL_COURSES from '@/graphql/queries/AllCourses.gql'
import SET_COURSE from '@/graphql/mutations/SetCourse.gql'
import UNPIN_COURSE from '@/graphql/mutations/UnpinCourse.gql'
import CREATE_COURSE from '@/graphql/mutations/CreateCourse.gql'
import DownIcon from '@/components/svg/DownIcon'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import moment from 'moment'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ResponsibleIcon from '@/components/svg/ResponsibleIcon'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Courses',
  components: {
    DownIcon,
    ResponsibleIcon
  },
  data () {
    return {
      showModal: false,
      title: 'Курсы',
      allCourses: [],
      selectedCourse: null,
      rows: [],
      actionType: null,
      period: new Date(Date.now() + 14 * 86400 * 1000),
      tableCourses: [
        {
          label: 'Сотрудник',
          field: 'name',
          hidden: false
        },
        {
          label: 'Курс ДОБРО ПОЖАЛОВАТЬ',
          field: 'welcome',
          hidden: false
        },
        {
          label: 'Курс ОСНОВЫ ЛИЗИНГА',
          field: 'basics',
          hidden: false
        },
        {
          label: 'Курс ПРОДУКТЫ ЛИЗИНГА',
          field: 'products',
          hidden: false
        },
        {
          label: 'Курс ОСНОВЫ ПРОДАЖ',
          field: 'sales',
          hidden: false
        },
        {
          label: 'Книга продаж 1',
          field: 'book1',
          hidden: false
        },
        {
          label: 'Книга продаж 2',
          field: 'book2',
          hidden: false
        }
      ],
      newCourseData: {
        title: null,
        link: null,
        description: null
      },
      selectedRows: [],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY HH:mm').toDate() : null
        }
      },
      statusSelect: [
        {
          label: 'Все',
          value: null
        },
        {
          label: 'Просмотрен',
          value: 'viewed'
        },
        {
          label: 'Завершен',
          value: 'completed'
        },
        {
          label: 'Не завершен',
          value: 'not_completed'
        },
        {
          label: 'Просрочен',
          value: 'overdue'
        },
        {
          label: 'Завершен с просрочкой',
          value: 'overdue_completed'
        }
      ],
      filter: {
        status: null
      },
      clientFilter: {
        deadline: true,
        empty: false
      },
      vcoConfig: {
        handler: () => {
          this.actionType = null
        },
        middleware: (event) => {
          let name = event.target.getAttribute('class') || ''
          return name !== null ? !name.includes('mx-') : true
        },
        events: ['dblclick', 'click'],
        isActive: true
      }
    }
  },
  async created () {
    this.doneLoadingCourses()
  },
  methods: {
    checkTitle (columnTitle, courseTitle) {
      return RegExp(courseTitle, 'i').test(columnTitle.toLowerCase())
    },
    findCourseInArray (courseList, search) {
      let result = null
      courseList.forEach(course => {
        if (RegExp(search).test(course.link)) result = course
      })
      return result
    },
    async onUserCourseChange () {
      const input = {
        userIds: this.selectedRows,
        deadline: this.period,
        courseId: this.selectedCourse ? this.selectedCourse.value : this.allCourses[0].id
      }
      await this.$apollo
        .mutate({
          mutation: SET_COURSE,
          variables: input,
          update: async (store, { data }) => {
            if (data) {
              if (!this.selectedCourse) {
                await this.$apollo
                  .mutate({
                    mutation: SET_COURSE,
                    variables: {
                      userIds: this.selectedRows,
                      deadline: this.period,
                      courseId: this.allCourses[1].id
                    },
                    update: async (store, { data }) => {
                      if (data) {
                        this.$router.go()
                      }
                    }
                  }).catch((error) => {
                    _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
                      this.$notify({
                        group: 'lsg-notify',
                        text: item
                      })
                    })
                  })
              } else {
                this.$router.go()
              }
            }
          }
        }).catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    viewCourse (link, block) {
      if (!block) {
        window.open(`${link}?hash=${this.$store.state.me._id}`, '_blank')
        this.$apollo.mutate({
          mutation: VIEW_COURSE,
          variables: {
            link
          }
        })
        setTimeout(() => {
          this.$router.go()
        }, 3000)
      }
    },
    doneLoadingUserCourses ({ data }) {
      if (data) {
        if (!this.filter.status) {
          this.rows = data.allUsersCourses || []
        } else {
          this.rows = data.allUsersCourses.filter(elem => elem.courses && elem.courses.length > 0) || []
        }
      }
    },
    async unpinCourse () {
      const input = {
        course_id: this.selectedCourse.value,
        user_ids: this.selectedRows
      }
      await this.$apollo
        .mutate({
          mutation: UNPIN_COURSE,
          variables: input,
          update: (store, { data }) => {
            if (data) {
              this.$router.go()
            }
          }
        }).catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    async createCourse () {
      await this.$apollo
        .mutate({
          mutation: CREATE_COURSE,
          variables: { input: this.newCourseData },
          update: (store, { data }) => {
            if (data) {
              this.$router.go()
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    async doneLoadingCourses () {
      await this.$apollo
        .mutate({
          mutation: ALL_COURSES,
          variables: {},
          update: (store, { data }) => {
            if (data) {
              this.allCourses = data.allCourses
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    onStatusChange (e) {
      if (e) {
        this.filter.status = e
      } else delete this.filter.status
    },
    async selectedRowsChange (e) {
      this.selectedRows = e.selectedRows.map(elem => elem.id)
    }
  }
}
</script>

<style lang="stylus" scoped>
.strelka-right-5 {
  transform: rotate(270deg);
}

.cabinet__item--client {
  position: relative;
}

.watermark {
  position: absolute;
  top: 100px;
  left: 80px;
  /* width: 50%; */
  max-width: 550px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border: 2px solid rgb(0, 128, 0);
  background: rgba(0, 128, 0, 0.25);
  transform: rotate(-20deg);
  word-wrap: break-word;
}

.watermark > span {
  font-size: 30px;
  font-weight: bold;
  color: rgb(0, 128, 0);
}

.watermark--viewed {
  border: 2px solid rgb(78, 85, 90);
  background: rgba(78, 85, 90, 0.25);
}

.watermark--viewed > span {
  color: rgb(78, 85, 90);
}

.watermark--overdue {
  border: 2px solid rgb(255, 0, 0);
  background: rgba(255, 0, 0, 0.25);
}

.watermark--overdue > span {
  color: rgb(255, 0, 0);
}

.course-blocked {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  pointer-events: none !important;
}

.cabinet
  &__bar
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap

    +below(1040px)
      justify-content center

    &__links {
      display flex
      justify-content space-between
      width 400px
      margin-bottom 20px
    }

    &__input
      max-width 500px
      width 100%
      margin 0 0 20px 0
      position relative

      a
        display flex
        align-items center
        absolute right 16px top bottom

  &__list
    display flex
    flex-direction column
    align-items flex-start
    margin -8px 0

  &__list--client
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 15px
    align-items start

    +below(1600px)
      grid-template-columns repeat(2, 1fr)

    +below(970px)
      grid-template-columns repeat(1, 1fr)

  &__item--client
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding 40px 60px 40px 40px
    width 100%
    margin 8px 0
    position relative

    &_active
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      background: $white;
      border: 1px solid rgba($darkgray, 0.3);
      box-sizing: border-box;
      border-radius: 10px;
      padding 40px
      width 100%
      margin 8px 0
      position relative
      max-height auto

    &:hover
      box-shadow: 0 10px 30px rgba($darker, 0.17);

    &__state
      absolute right 40px top 40px bottom
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
      flex-shrink 0

      svg
        width 100%
        height 100%
        fill $dark
        transition fill 0.3s

      &:hover
        svg
          fill $orange

    &__name
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin 0
      color $orange

    &__contact
      display flex
      flex-direction column
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-top 20px
      color $dark

      ^[0]:hover
        color $dark

  &__answer
    color $black

.cabinet__item--plus {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  color: #d9d9d9;
  cursor: pointer;
  opacity: 0.5;
}
</style>
